import { Container } from './styles';

import Logo from './assets/logo.svg';
import { useState } from 'react';
import axios from 'axios';

function App() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleDelete = async () => {
    try {
      await axios.post(`https://api.pixelmagic.com.br/public/api/deleteUserAccount`, {
        email,
        password,
      });
      alert('Conta deletada com sucesso!');
    } catch {
      alert('Houve um erro ao deletar sua conta tente novamente');
    }
  };

  return (
    <Container>
      <form>
        <header>
          <img src={Logo} alt="" />
          <h1>IRONPLAN</h1>
        </header>
        <input
          type="text"
          placeholder="E-mail"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Senha"
          onChange={(e) => setPassword(e.target.value)}
        />

        <p>
          Todos os seus dados serão deletados permanentemente. Tem certeza que
          quer fazer isso?
        </p>
        <button type="button" onClick={handleDelete}>
          Deletar conta
        </button>
      </form>
    </Container>
  );
}

export default App;
