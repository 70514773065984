import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #282a35f2;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0;
  margin: 0;
  box-sizing: border-box;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 482px;
    height: 450px;
    padding: 0 16px;

    header {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 56px;
        height: 56px;
      }

      h1 {
        font-size: 24px;
        color: white;
        margin-left: 16px;
        font-family: 'Open Sans', sans-serif;
      }
    }

    input {
      width: 100%;
      margin-top: 16px;
      height: 40px;
      border-radius: 16px;
      background: #282a35;

      border: 0;
      padding-left: 16px;
      font-family: 'Open Sans', sans-serif;
      box-sizing: border-box;
      outline: none;
      color: white;
    }

    p {
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-size: 12px;
      font-weight: 400;
      text-align: justify;
      margin: 16px;
    }

    button {
      width: 100%;
      height: 40px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      margin: 0;
      border-radius: 16px;
      background-color: #85f689;
      color: #282a35;
      border: 0;
      cursor: pointer;
      margin-top: 8px;
    }
  }
`;
